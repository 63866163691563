/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Signer, utils } from "ethers";
import type { Provider } from "@ethersproject/providers";
import type {
  GenArt721Minter,
  GenArt721MinterInterface,
} from "../GenArt721Minter";

const _abi = [
  {
    inputs: [
      {
        internalType: "address",
        name: "_genArt721Address",
        type: "address",
      },
      {
        internalType: "address",
        name: "_minterFilter",
        type: "address",
      },
    ],
    stateMutability: "nonpayable",
    type: "constructor",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "uint256",
        name: "_projectId",
        type: "uint256",
      },
      {
        indexed: true,
        internalType: "uint256",
        name: "_pricePerTokenInWei",
        type: "uint256",
      },
    ],
    name: "PricePerTokenInWeiUpdated",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "uint256",
        name: "_projectId",
        type: "uint256",
      },
      {
        indexed: true,
        internalType: "address",
        name: "_currencyAddress",
        type: "address",
      },
      {
        indexed: false,
        internalType: "string",
        name: "_currencySymbol",
        type: "string",
      },
    ],
    name: "ProjectCurrencyInfoUpdated",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "uint256",
        name: "_projectId",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "bool",
        name: "_purchaseToDisabled",
        type: "bool",
      },
    ],
    name: "PurchaseToDisabledUpdated",
    type: "event",
  },
  {
    inputs: [],
    name: "genArt721CoreAddress",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_projectId",
        type: "uint256",
      },
    ],
    name: "getPriceInfo",
    outputs: [
      {
        internalType: "bool",
        name: "isConfigured",
        type: "bool",
      },
      {
        internalType: "uint256",
        name: "tokenPriceInWei",
        type: "uint256",
      },
      {
        internalType: "string",
        name: "currencySymbol",
        type: "string",
      },
      {
        internalType: "address",
        name: "currencyAddress",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "minterFilterAddress",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "minterType",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    name: "projectMaxHasBeenInvoked",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    name: "projectMaxInvocations",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_projectId",
        type: "uint256",
      },
    ],
    name: "purchase",
    outputs: [
      {
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
    ],
    stateMutability: "payable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_to",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "_projectId",
        type: "uint256",
      },
    ],
    name: "purchaseTo",
    outputs: [
      {
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
    ],
    stateMutability: "payable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_projectId",
        type: "uint256",
      },
    ],
    name: "setProjectMaxInvocations",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_projectId",
        type: "uint256",
      },
    ],
    name: "togglePurchaseToDisabled",
    outputs: [],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_projectId",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "_pricePerTokenInWei",
        type: "uint256",
      },
    ],
    name: "updatePricePerTokenInWei",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
];

export class GenArt721Minter__factory {
  static readonly abi = _abi;
  static createInterface(): GenArt721MinterInterface {
    return new utils.Interface(_abi) as GenArt721MinterInterface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): GenArt721Minter {
    return new Contract(address, _abi, signerOrProvider) as GenArt721Minter;
  }
}
